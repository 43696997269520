import {randomStr} from "@/utils/common";

class Upload {
	constructor(file, block_size, api_func) {
		this.file = file;
		this.block_size = block_size;
		this.start = 0;		//切割起始点
		this.end = this.start + block_size;
		this.total_block_num = Math.ceil(file.size/block_size); 	//总分割块数
		this.file_tmp = randomStr(8);
		this.action = api_func;

	}
	//分片
	sliceFile() {
		let blob = this.file.slice(this.start, this.end);
		this.start = this.end;
		this.end = this.start + this.block_size;
		return blob;
	}

	uploadSlice(index) {
		let blob = this.sliceFile();
		let file_name_ext = this.file.name.split('.');

		let file_name = null;
		let file_ext = null;
		// 处理文件名中出现.的文件
		if (file_name_ext.length > 2) {
			[file_name, file_ext] = [file_name_ext.slice(0, file_name_ext.length-1).join('.'), file_name_ext[file_name_ext.length - 1]];
		} else {
			[file_name, file_ext] = file_name_ext;
		}

		file_ext = file_ext.toLowerCase();

		let blobFile = new File([blob], `${file_name}.${index}.${file_ext}`);
		let formData = new FormData();
		formData.append('file', blobFile);
		formData.append('index', index);
		formData.append('total', this.total_block_num);
		formData.append('flag', this.file_tmp);
		formData.append('slice_size', this.block_size);
		return this.action(formData);
	}
	uploadSliceVideo(index) {
		let blob = this.sliceFile();
		let file_name_ext = this.file.name.split('.');

		let file_name = null;
		let file_ext = null;
		// 处理文件名中出现.的文件
		if (file_name_ext.length > 2) {
			[file_name, file_ext] = [file_name_ext.slice(0, file_name_ext.length-1).join('.'), file_name_ext[file_name_ext.length - 1]];
		} else {
			[file_name, file_ext] = file_name_ext;
		}

		file_ext = file_ext.toLowerCase();

		let blobFile = new File([blob], `${file_name}.${index}.${file_ext}`);
		let formData = new FormData();
		formData.append('file', blobFile);
		formData.append('index', index);
		formData.append('total', this.total_block_num);
		formData.append('flag', this.file_tmp);
		formData.append('slice_size', this.block_size);
		return this.action(formData);
	}
}

export default Upload;