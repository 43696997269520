<template>
    <div class="goods-shoot-content">
        <div>
<!--            <div class="goods-shoot-header">-->
<!--                <span style="margin-bottom: 28px;">素材下载</span>-->
<!--            </div>-->
            <div class="task-table">
                <div class="table-title">
                    <span class="text">任务详情</span>
                    <span class="text">素材下载</span>
                    <span class="text">商品视频（支持拓展名：rar和zip，文件大小不能超过100M）</span>
                </div>
                <div class="table-body">
                    <span class="text">商品视频拍摄与处理</span>
                    <span class="text download-materials" @click="downloadMaterials">点击下载</span>
                    <div class="upload text">
                        <el-button plain onclick="uploadFile.click()">选择文件</el-button>
                        <input class="file-upload-btn" ref="uploadFile" id="uploadFile" type="file"
                               accept="application/zip, .rar" title=""
                               @change="changeFile($event)">
                        <span class="upload-text">{{filesName}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div  style="text-align: center">
            <el-button type="primary" @click="saveGoodsVideoShoot" :disabled="submit_text === '上传中'">{{submit_text}}</el-button>
        </div>
    </div>
</template>

<script>
    import  Upload from '@/utils/slice_upload';
    import {collectUp_video, collectMerge, collectSave_video, collectVideo, stuOpGetExamInfo} from '@/utils/apis'
    export default {
        name: "GoodsVideoShoot",
        data() {
          return {
              //文件名称
              filesName: '未选择任何文件',
              //分片上传
              uploadInstance: null,
              material: '',
              //提交id
              id: null,
              //提交文字
              submit_text: '提交',
              //要下载的素材
              downloadMaterial: ''
          }
        },
        created() {
            this.getGoodsVideo();
            this.getDownloadMaterial();
        },
        methods: {
            //获取学生考试商品视频拍摄与处理（商品视频）内容
            getGoodsVideo() {
                let param = {}
                if (this.$route.query.id) {
                    param.op_id = this.$route.query.id;
                    param.course_id = this.$route.query.course_id;
                }
                collectVideo(param).then((res) => {
                    if (res.data.id) {
                        this.id = res.data.id;
                        this.filesName = res.data.file_name;
                        this.material = res.data.file_path;
                    }
                    if (res.data.length === 0) {
                        this.filesName = '未选择任何文件';
                    }
                }).catch((err) => {
                    console.log(err);
                })
            },
            //获取下载素材
            getDownloadMaterial() {
                let p = {
                    type: "goods_info_collect"
                }
                if (this.$route.query.id) {
                    p.op_id = this.$route.query.id;
                }
                stuOpGetExamInfo(p).then((res) => {
                    this.downloadMaterial = res.data.file_download;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //下载
            downloadMaterials() {
                window.location.href = this.downloadMaterial;
            },
            //上传素材
            changeFile(event) {
                if (!event.target.files[0].size) {
                    return this.$message.warning('文件大小异常，请检查文件');
                }
                if (event.target.files[0].size > 1024 * 1024 * 100) {
                    this.$message.warning('文件超过100M');
                    return;
                } else {
                    let chunkSize = 1024 * 1024 *10;
                    let file = event.target.files[0];
                    this.uploadInstance = new Upload(file, chunkSize, collectUp_video);
                    this.filesName = file.name;
                    this.submit_text = '上传中';
                    let index = 0;
                    this.uploadSlice(index);
                }
            },
            uploadSlice(index) {
                let num = this.uploadInstance.total_block_num;
                if (index === num && num !== 1) {
                    //合并分片
                    collectMerge({
                        flag: this.uploadInstance.file_tmp,
                        slice_size: this.uploadInstance.block_size
                    }).then(res => {
                        if (res.code === 200) {
                            this.submit_text = '提交';
                            this.material = res.data.src;
                            this.percent = 100;
                            this.$refs.uploadFile.value = null
                            this.$message.success(res.msg);
                        }
                    });
                    return;
                }
                this.uploadInstance.uploadSlice(index).then(res => {
                    if (res.code === 200) {
                        if (res.data.is_slice) { //is_slice 为true则为分片上传，后面需要合并分片,若为false则无须分片，或者文件大小<分片尺寸，直接上传就行
                            this.percent = Math.ceil((index / num) * 100);
                            this.uploadSlice(++index);
                        } else {
                            this.material = res.data.src;
                            this.submit_text = '提交';
                            this.percent = 100;
                            this.$refs.uploadFile.value = null
                            this.$message.success(res.msg);
                        }
                    }
                });
            },
            //提交
            saveGoodsVideoShoot() {
                let param = {
                    file_path: this.material,
                    file_name: this.filesName
                }
                if (this.$route.query.id) {
                    param.course_id = Number(this.$route.query.course_id);
                    param.op_id = Number(this.$route.query.id);
                }
                if (this.id) {
                    param.id = this.id;
                }
                collectSave_video(param).then((res) => {
                    this.$message.success(res.msg);
                }).catch((err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .goods-shoot-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        .goods-shoot-header {
            display: flex;
            justify-content: space-between;
            /*align-items: center;*/
            /*height: 58px;*/
            border-bottom: 2px solid #F1F5FF;
            span {
                color: #333;
                font-size: 16px;
            }
        }
    }
    .task-table {
        border: 1px solid #E5E5E5;
        margin-top: 16px;
        .table-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 60px;
            line-height: 60px;
            background: #F6F6F6;
            border-bottom: 1px solid #EEEEEE;
            box-sizing: border-box;
            padding: 0 40px;
        }
        .upload {
            display: flex !important;
            align-items: center;
        }
        .text {
            display: inline-block;
            &:nth-child(1) {
                width: 150px;
            }
            &:nth-child(3) {
                width: 550px;
            }
        }
        .table-body {
            display: flex;
            justify-content: space-between;
            height: 70px;
            line-height: 70px;
            padding: 0 40px;
        }
    }
    .file-upload-btn {
        display: none;
    }
    .upload-text {
        display: inline-block;
        flex: 1;
        width: 1%;
        color: #666;
        margin-left: 10px;
    }
    .is-plain {
        background: #FFF;
        border-color: #1CB064;
        color: #1CB064;
        border-radius: 2px;
    }
    .download-materials {
        color: #2461EF;
        cursor: pointer;
    }
</style>